<template>
  <div>
    <div class="wrapper d-flex align-items-stretch">
      <MainMenu />
      <div id="content" class="p-4 p-md-5 pt-5">
        <h1 class="text-center">{{ $t("Copla.Views.Subtitles.Title") }}</h1>
        <div class="border rounded secciones mt-4" id="configuration">
          <h2>{{ $t("Copla.Common.Subtitles.Configuration") }}</h2>
          <div class="form-group row mt-4">
            <label
              for="title"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Name") }}</label
            >
            <div class="col-xl-10 mt-xl-1">
              <input
                type="text"
                class="form-control"
                name="title"
                id="title"
                min="1"
                v-model="title"
                :readonly="transcribing"
              />
            </div>
            <label
              for="server"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Language") }}</label
            >
            <div class="col-xl-4 mt-xl-1">
              <select
                v-model="language"
                class="custom-select"
                :disabled="transcribing || source == 'Mercurio'"
              >
                <option
                  v-bind:value="id"
                  v-for="(value, id) in languages"
                  :key="id"
                >
                  {{ value }}
                </option>
              </select>
            </div>
            <label
              for="server"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Profanity") }}</label
            >
            <div class="col-xl-4 mt-xl-1">
              <select
                v-model="profanity"
                class="custom-select"
                :disabled="transcribing || source == 'Mercurio'"
              >
                <option
                  v-bind:value="id"
                  v-for="(value, id) in profanities"
                  :key="id"
                >
                  {{ value }}
                </option>
              </select>
            </div>
            <label
              for="lines"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Lines") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="lines"
                id="lines"
                min="1"
                v-model="lines"
                :readonly="transcribing"
              />
            </div>
            <label
              for="characters"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Characters") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="characters"
                id="characters"
                min="1"
                max="120"
                v-model="characters"
                :readonly="transcribing"
              />
            </div>
            <label
              for="fps"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.FPS") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="characters"
                id="characters"
                min="1"
                max="120"
                v-model="fps"
                :readonly="
                  transcribing || source == 'Mercurio' || source == 'Live'
                "
              />
            </div>
            <label
              for="server"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Source") }}</label
            >
            <div class="col-xl-10 mt-xl-1">
              <select
                v-model="source"
                class="custom-select"
                :disabled="transcribing"
              >
                <option
                  v-bind:value="id"
                  v-for="(value, id) in sources"
                  :key="id"
                >
                  {{ value }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row" v-if="source == 'Mercurio'">
            <label
              for="session"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.SessionID") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="session"
                id="session"
                min="1"
                v-model="mercurio.session"
              />
            </div>
            <label
              for="group"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.GroupID") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="number"
                class="form-control"
                name="group"
                id="group"
                min="1"
                max="16"
                v-model="mercurio.group"
              />
            </div>
            <label
              for="fps"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.MercurioServer") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <select v-model="mercurio.server" class="custom-select">
                <option selected value="CESyA">{{
                  $t("Copla.Common.Lists.MercurioServers.CESyA")
                }}</option>
                <option value="GoogleCloud">{{
                  $t("Copla.Common.Lists.MercurioServers.GoogleCloud")
                }}</option>
              </select>
            </div>
            <label for="start" class="mt-1 col-2 col-form-label text-center">{{
              $t("Copla.Common.Form.Start")
            }}</label>
            <div class="col-4 mt-1">
              <input
                type="datetime-local"
                class="form-control"
                id="start"
                name="start"
                v-model="mercurio.start"
                required
              />
            </div>
            <label for="end" class="mt-1 col-2 col-form-label text-center">{{
              $t("Copla.Common.Form.End")
            }}</label>
            <div class="col-4 mt-1">
              <input
                type="datetime-local"
                class="form-control"
                id="end"
                name="end"
                v-model="mercurio.end"
                required
              />
            </div>
          </div>
          <div class="form-group row" v-if="source == 'Live'">
            <label
              for="mercurioSession"
              class="col-xl-4 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Messages.Mercurio") }}</label
            >
            <label
              for="mercurioSession"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Session") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="text"
                class="form-control"
                name="mercurioSession"
                id="mercurioSession"
                v-model="mercurioSession"
              />
            </div>
            <label
              for="mercurioViewer"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Viewer") }}</label
            >
            <div class="col-xl-2 mt-xl-1">
              <input
                type="text"
                class="form-control"
                name="mercurioViewer"
                id="mercurioViewer"
                v-model="mercurioViewer"
              />
            </div>
            <label
              for="grammar"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Vocabulary") }}</label
            >
            <div class="col-xl-8 mt-1">
              <input
                type="text"
                class="form-control"
                name="grammar"
                id="grammar"
                v-model="word"
                :readonly="transcribing"
              />
            </div>
            <div class="col-xl-2 mt-xl-1 mt-2">
              <button
                type="button"
                id="add"
                name="add"
                class="btn btn-add-vocabulary"
                :class="transcribing ? 'btn-danger' : 'btn-success'"
                v-on:click="addGrammar"
                :disabled="transcribing"
              >
                {{ $t("Copla.Common.Buttons.Add") }}
              </button>
            </div>
            <div class="col mt-3" v-if="grammarList.length > 0">
              <ul class="list-inline">
                <li
                  class="list-inline-item vocabulario"
                  v-bind:value="id"
                  v-for="(value, id) in grammarList"
                  :key="id"
                >
                  {{ value }}
                  <span class="deleteGrammar" v-on:click="deleteGrammar(id)"
                    >&#10008;</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group row" v-if="source == 'Transcription'">
            <label
              for="grammar"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.Transcription") }}</label
            >
            <div class="col-xl-10 mt-1">
              <textarea
                class="form-control"
                name="transcription"
                id="transcription"
                v-model="transcription"
              ></textarea>
            </div>
          </div>
          <div class="form-group row" v-if="source == 'File'">
            <label
              for="file"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.MP3File") }}</label
            >
            <div class="col-xl-10 mt-1">
              <input
                type="file"
                class="form-control"
                id="file"
                name="file"
                ref="file"
                v-on:change="handleFileUpload()"
                accept=".mp3"
                required
              />
            </div>
          </div>
          <div class="form-group row" v-if="source == 'SRT'">
            <label
              for="file"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.SRTFile") }}</label
            >
            <div class="col-xl-10 mt-1">
              <input
                type="file"
                class="form-control"
                id="file"
                name="file"
                ref="file"
                v-on:change="handleFileUpload()"
                accept=".srt"
                required
              />
            </div>
          </div>
          <div class="form-group row" v-if="source == 'Startit'">
            <label
              for="file"
              class="col-xl-2 col-form-label text-left text-xl-center mt-1"
              >{{ $t("Copla.Common.Form.StartitFile") }}</label
            >
            <div class="col-xl-10 mt-1">
              <input
                type="file"
                class="form-control"
                id="file"
                name="file"
                ref="file"
                v-on:change="handleFileUpload()"
                accept=".sti"
                required
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class=" col mr-3">
              <button
                type="button"
                id="comenzar"
                name="comenzar"
                class="btn"
                :class="transcribing ? 'btn-danger' : 'btn-success'"
                v-on:click="transcribe"
              >
                {{ btnText }}
              </button>
              <button
                type="button"
                id="save"
                name="save"
                class="btn ml-3"
                :class="transcribing ? 'btn-danger' : 'btn-primary'"
                v-on:click="postCAF('')"
                v-if="!transcribing && ar.live != '' && source == 'Live'"
              >
                {{ $t("Copla.Common.Buttons.Save") }}
              </button>
            </div>
          </div>
        </div>
        <div
          class="border rounded secciones mt-4 text-left"
          id="transcription-box"
          v-if="source == 'Live'"
        >
          <span>{{ ar.live }}</span>
        </div>
        <div
          v-if="error != ''"
          class="border rounded secciones mt-4"
          id="error"
        >
          {{ error }}
        </div>
        <table class="table table-sm table-striped table-dark mt-5">
          <caption>
            {{
              $t("Copla.Views.Subtitles.Table.Updated")
            }}:
            {{
              updateTable
            }}
          </caption>
          <thead>
            <tr>
              <th>{{ $t("Copla.Views.Subtitles.Table.Date") }}</th>
              <th>{{ $t("Copla.Views.Subtitles.Table.Name") }}</th>
              <th>{{ $t("Copla.Views.Subtitles.Table.Type") }}</th>
              <th>{{ $t("Copla.Views.Subtitles.Table.Language") }}</th>
              <th>{{ $t("Copla.Views.Subtitles.Table.SRT") }}</th>
              <th>{{ $t("Copla.Views.Subtitles.Table.Transcription") }}</th>
              <th>
                {{ $t("Copla.Views.Subtitles.Table.Status") }}
              </th>
              <th>
                <span class="reload" v-on:click="getCafs()">&#x21bb;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(caf, i) in showCafs" :key="i" :id="i">
              <td class="align-middle">{{ datetimeString(caf.date) }}</td>
              <td class="align-middle">{{ caf.name }}</td>
              <td class="align-middle">{{ caf.type }}</td>
              <td class="align-middle">{{ caf.language }}</td>
              <td>
                <button
                  v-if="caf.status == 'Succeeded'"
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop=""
                  v-on:click="getSRT(caf.id)"
                >
                  {{ $t("Copla.Common.Buttons.Download") }}
                </button>
              </td>
              <td>
                <button
                  v-if="caf.status == 'Succeeded'"
                  type="button"
                  class="btn btn-primary"
                  v-on:click.stop=""
                  v-on:click="getTranscription(caf.id)"
                >
                  {{ $t("Copla.Common.Buttons.Download") }}
                </button>
              </td>
              <td>
                <router-link
                  v-if="caf.status == 'Succeeded'"
                  class="inline"
                  :to="
                    $i18nRoute({ name: 'versions', params: { cafId: caf.id } })
                  "
                >
                  <button
                    type="button"
                    class="btn"
                    :class="statusButtons(caf.status)"
                  >
                    {{ $t("Copla.Common.Lists.Status." + caf.status) }}
                  </button>
                </router-link>
                <button
                  type="button"
                  class="btn"
                  :class="statusButtons(caf.status)"
                  v-if="caf.status != 'Succeeded'"
                >
                  {{ $t("Copla.Common.Lists.Status." + caf.status) }}
                </button>
              </td>
              <td
                class="float-right reload align-middle"
                v-on:click="deleteCaf(caf.id)"
              >
                <i class="fas fa-trash"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="">
          <Pagination
            v-if="cafs"
            :total-pages="totalPages"
            :per-page="pagination"
            :current-page="currentPage"
            @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>
    <modal-loading :showModal="showModalLoading" :message="messageModal" />
  </div>
</template>

<script>
import AzureAPI from "../js/AzureAPI";
import MainMenu from "../components/MainMenu";
import formatSubtitlesAPI from "../js/formatSubtitlesAPI";
import AzureRecognizer from "../js/AzureRecognizer";
import auxiliarFunctions from "../js/AuxiliarFunctions";
import ModalLoading from "../components/ModalLoading.vue";
import Pagination from "../components/Pagination.vue";
export default {
  name: "Subtitles",
  components: {
    MainMenu,
    ModalLoading,
    Pagination
  },
  data() {
    return {
      ar: new AzureRecognizer({}),
      word: "",
      grammarList: [],
      transcription: "",
      transcribing: false,
      recognizer: undefined,
      btnText: this.$t("Copla.Common.Buttons.Start"),
      languages: this.$t("Copla.Common.Lists.Languages"),
      profanities: this.$t("Copla.Common.Lists.Profanities"),
      language: "es-ES",
      timedText: {},
      pagination: 10,
      currentPage: 1,
      profanity: 2,
      lines: 2,
      characters: 40,
      fps: 25,
      fileDownload: "transcription.txt",
      url: this.$route,
      base_url: process.env.VUE_APP_BASE_URL,
      file: "",
      lastOffset: 0,
      initOffset: 0,
      init: 0,
      id: -1,
      source: "File",
      sources: this.$t("Copla.Common.Lists.Sources"),
      title: "Prueba",
      azureAPI: new AzureAPI({
        key: process.env.VUE_APP_AZURE_KEY,
        project: process.env.VUE_APP_AZURE_PROJECT,
        location: process.env.VUE_APP_AZURE_LOCATION
      }),
      fsAPI: new formatSubtitlesAPI({
        base_url: process.env.VUE_APP_BASE_URL,
        token: this.$store.state.accessToken
      }),
      showModalLoading: false,
      messageModal: "",
      cafs: [],
      updateTable: 0,
      mercurio: {
        session: 103,
        group: 1,
        server: "CESyA",
        start: "2020-10-01T11:00",
        end: "2020-10-01T13:00"
      },
      error: "",
      mercurioSession: "",
      mercurioViewer: ""
    };
  },
  watch: {
    transcribing: function(val) {
      this.btnText = val
        ? this.$t("Copla.Common.Buttons.Stop")
        : this.$t("Copla.Common.Buttons.Start");
    }
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
    },
    statusButtons(status) {
      let classes = {
        NotStarted: "btn-primary no-click",
        Running: "btn-warning no-click",
        Failed: "btn-danger no-click",
        Succeeded: "btn-success"
      };
      return classes[status];
    },
    showModal(status, message = "") {
      this.messageModal = message;
      this.showModalLoading = status;
    },
    checkForm: function() {
      let someFieldBlank =
        this.title == "" || this.lines == "" || this.characters == "";
      if (someFieldBlank) {
        alert(this.$t("Copla.Common.Errors.FieldsNotEmpty"));
        return false;
      }
      if (this.source == "File" && this.file == "") {
        alert(this.$t("Copla.Common.Errors.FieldsNotEmpty"));
        return false;
      }
      return true;
    },
    deleteCaf: function(id) {
      if (confirm(this.$t("Copla.Common.Messages.Confirm"))) {
        this.showModal(true, this.$t("Copla.Common.Modal.Deleting"));
        let self = this;
        this.fsAPI.deleteCAF(id).then(response => {
          if (!response.error) {
            this.showModal(false);
            self.getCafs();
            self.updateTable = auxiliarFunctions.datetimeString(
              Date.now() / 1000
            );
          } else {
            this.showModal(false);
            this.error = "Error: " + response.data;
          }
        });
      }
    },
    getSRT(id) {
      this.showModal(true, this.$t("Copla.Common.Modal.Downloading"));
      this.fsAPI.getSRT(id, 0).then(response => {
        if (!response.error) {
          auxiliarFunctions.downloadFile(response.data.data, "subs.srt");
          this.showModal(false);
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    },
    getTranscription(id) {
      this.showModal(true, this.$t("Copla.Common.Modal.Downloading"));
      this.fsAPI.getTranscription(id, 0).then(response => {
        if (!response.error) {
          auxiliarFunctions.downloadFile(
            response.data.data,
            "transcription.txt"
          );
          this.showModal(false);
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    },
    getCafs: function() {
      this.error = "";
      this.showModal(true, this.$t("Copla.Common.Modal.Loading"));
      let self = this;
      this.fsAPI.getCAFs().then(response => {
        this.showModal(false);
        if (!response.error) {
          self.cafs = response.data.data;
          self.updateTable = auxiliarFunctions.datetimeString(
            Date.now() / 1000
          );
        } else {
          this.error = "Error: " + response.data;
        }
      });
    },
    datetimeString: function(ts) {
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      let date = new Date(ts * 1000);
      let hours = zeroPad(date.getHours(), 2);
      let minutes = zeroPad(date.getMinutes(), 2);
      let seconds = zeroPad(date.getSeconds(), 2);
      let day = zeroPad(date.getDate(), 2);
      let month = zeroPad(date.getMonth() + 1, 2);
      let year = date.getFullYear();
      return (
        day +
        "/" +
        month +
        "/" +
        year +
        " - " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    addGrammar: function() {
      if (this.word != "") {
        this.grammarList.push(this.word);
        this.word = "";
      }
    },
    deleteGrammar: function(id) {
      this.grammarList.splice(id, 1);
    },
    handleFileUpload: function() {
      this.file = this.$refs.file.files[0];
    },
    transcribe: function() {
      this.error = "";
      if (this.source != "Live" || !this.transcribing) {
        if (this.checkForm()) {
          switch (this.source) {
            case "Live":
              this.transcribing = true;
              this.startLive();
              break;
            case "File":
              this.uploadFile();
              break;
            case "Mercurio":
              this.postMercurio();
              break;
            case "Transcription":
              this.postTranscription();
              break;
            case "SRT":
              this.uploadFile();
              break;
            case "Startit":
              this.uploadFile();
              break;
          }
        }
      } else {
        this.transcribing = false;
        this.stopLive();
      }
    },
    startLive: function() {
      let options = {
        language: this.language,
        profanity: this.profanity,
        grammarList: this.grammarList
      };
      this.ar = new AzureRecognizer(options);
      this.ar.startContinuousRecognitionAsync(
        this.mercurioSession,
        this.mercurioViewer
      );
    },
    stopLive: function() {
      this.ar.stopContinuousRecognitionAsync();
    },
    uploadFile: function() {
      this.showModal(true, this.$t("Copla.Common.Modal.Uploading"));
      let self = this;
      let formData = new FormData();
      formData.append("_file", this.file);
      this.fsAPI.uploadFile(formData).then(response => {
        this.showModal(false);
        if (!response.error) {
          switch (self.source) {
            case "File":
              self.postAzure(response.data.data);
              break;
            case "SRT":
              self.postSRT(response.data.data);
              break;
            case "Startit":
              self.postStartit(response.data.data);
              break;
          }
        } else {
          this.error = "Error: " + response.data;
        }
      });
    },
    postTranscription() {
      this.showModal(true, this.$t("Copla.Common.Modal.Creating"));
      let self = this;
      let params = {
        _name: this.title,
        _language: this.language,
        _lines: this.lines,
        _characters: this.characters,
        _fps: this.fps,
        _transcription: this.transcription
      };
      this.fsAPI.postTranscription(params).then(response => {
        this.showModal(false);
        this.transcription = "";
        if (!response.error) {
          self.getCafs();
        } else {
          this.error = "Error: " + response.data;
        }
      });
    },
    postSRT: function(url) {
      let self = this;
      this.showModal(true, this.$t("Copla.Common.Modal.Creating"));
      let params = {
        _name: this.title,
        _language: this.language,
        _url: url
      };
      this.fsAPI.postSRT(params).then(response => {
        this.showModal(false);
        if (!response.error) {
          self.getCafs();
        } else {
          this.error = "Error: " + response.data;
        }
      });
    },
    postStartit: function(url) {
      let self = this;
      this.showModal(true, this.$t("Copla.Common.Modal.Creating"));
      let params = {
        _name: this.title,
        _language: this.language,
        _url: url
      };
      this.fsAPI.postStartit(params).then(response => {
        this.showModal(false);
        if (!response.error) {
          self.getCafs();
        } else {
          this.error = "Error: " + response.data;
        }
      });
    },
    postAzure: function(url) {
      this.showModal(true, this.$t("Copla.Common.Modal.Creating"));
      let self = this;
      let mp3 = "https://formatsubtitles." + self.base_url + "/" + url;
      let profanity = { 0: "Masked", 1: "Removed", 2: "None" };
      let params = {
        contentUrls: [mp3],
        properties: {
          diarizationEnabled: false,
          wordLevelTimestampsEnabled: true,
          punctuationMode: "Automatic",
          profanityFilterMode: profanity[self.profanity],
          email: this.$store.state.user,
          timeToLive: "P7D"
        },
        locale: self.language,
        displayName: self.title
      };
      this.azureAPI.createTranscription(params).then(response => {
        this.showModal(false);
        if (!response.error) {
          this.showModal(true, this.$t("Copla.Common.Modal.Creating"));
          this.postCAF(response.data.self);
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    },
    postMercurio: function() {
      this.showModal(true, this.$t("Copla.Common.Modal.MercurioSearching"));
      let self = this;
      let params = {
        _name: this.title,
        _profanity: this.profanity,
        _language: this.language,
        _lines: this.lines,
        _characters: this.characters,
        _fps: this.fps,
        _session: this.mercurio.session,
        _group: this.mercurio.group,
        _server: this.mercurio.server,
        _start: this.mercurio.start,
        _end: this.mercurio.end
      };
      this.fsAPI.postMercurio(params, self.id).then(response => {
        if (!response.error) {
          this.showModal(false);
          self.getCafs();
        } else {
          this.showModal(false);
          this.error = "Error: " + response.data;
        }
      });
    },
    postCAF: function(url) {
      let self = this;
      let transcription = "";
      let phrases = [];
      if (this.source == "Live") {
        (transcription = this.ar.CAF._transcription),
          (phrases = this.ar.CAF._phrases);
      }
      this.showModal(true, this.$t("Copla.Common.Modal.Saving"));
      let params = {
        _transcription: transcription,
        _phrases: phrases,
        _url: url,
        _name: self.title,
        _profanity: self.profanity,
        _language: self.language,
        _lines: this.lines,
        _fps: this.fps,
        _characters: this.characters
      };
      this.fsAPI.postCAF(params).then(response => {
        this.showModal(false);
        if (!response.error) {
          self.getCafs();
          self.ar.live = "";
        } else {
          self.error = "Error: " + response.data;
        }
      });
    }
  },
  mounted() {
    this.getCafs();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.cafs.length / this.pagination);
    },
    showCafs() {
      return this.cafs.slice(
        (this.currentPage - 1) * this.pagination,
        this.pagination * this.currentPage
      );
    }
  }
};
</script>

<style scoped>
#transcription {
  height: 200px;
}
.reload {
  font-family: Lucida Sans Unicode;
  float: right;
  cursor: pointer;
}
.btn-add-vocabulary {
  width: 100%;
}
.deleteGrammar {
  color: red;
}
.deleteGrammar:hover {
  cursor: pointer;
}
.vocabulario {
  border: 1px dotted black;
  padding: 10px;
}
h1 {
  font-size: 1.8em;
  font-weight: bold;
}
h2 {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
}
.secciones {
  padding: 10px;
}
#transcription-box {
  min-height: 100px;
  max-height: 300px;
  overflow: scroll;
}
.wrapper {
  width: 100%;
}

h1 {
  font-size: 1.8em;
  font-weight: bold;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

#content {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-color: white;
}

#error {
  font-size: 1.2em;
  color: red;
  font-weight: bold;
}
</style>
